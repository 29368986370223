/* eslint-disable react-hooks/exhaustive-deps */
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { Box, Button, TablePagination, TextField, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import DataTableHead from './DataTableHead';
import * as crudFactory from '../../factories/crud.factory';
import FuseLoading from '@fuse/core/FuseLoading';
import AppIcon from '../AppIcon/AppIcon';
import useIsMountedRef from 'app/shared/hooks/useIsMountedRef';
import { sortByString } from 'app/shared/helpers/sorters';

let lastSearchChanged = 0;

function Search({ onChange }) {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    lastSearchChanged = Date.now();
    setTimeout(() => {
      if (Date.now() - lastSearchChanged >= 500) {
        onChange(searchText);
      }
    }, 500);
    return () => true;
  }, [searchText]);

  const ClearButton = () => (
    <Button size="small" onClick={() => setSearchText('')} className="mt-1">
      Limpiar
    </Button>
  );

  return (
    <TextField
      placeholder="Buscar por nombre"
      className="m-10 flex-1"
      variant="outlined"
      size="small"
      value={searchText}
      onChange={(ev) => setSearchText(ev.target.value)}
      InputProps={{ startAdornment: <Icon color="action">search</Icon>, endAdornment: <ClearButton /> }}
    />
  );
}

function DataTable({
  columns = [],
  collection,
  query,
  onRowClick,
  reload,
  sortBy,
  search = true,
  searchField = 'name',
  customRowsPerPage = 100,
  aggregate,
  ToolbarComponent,
  onDataLoaded,
}) {
  columns = columns.filter((f) => !f.hidden);
  const fields = columns.map((e) => e.field);
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(customRowsPerPage);
  const [data, setData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText, data]);

  function handleSearch(searchText) {
    let dataToHandle = data;

    if (sortBy === 'name') {
      dataToHandle = sortByString(data, sortBy);
    }

    if (searchText.length !== 0) {
      setPage(0);
      const [rootField, nestedField] = searchField.split('.');
      setDataToShow(
        dataToHandle.filter((item) => {
          const normalized = _normalize(nestedField ? item[rootField][nestedField] : item[rootField]);
          return normalized.toLowerCase().includes(searchText.toLowerCase());
        })
      );
    } else {
      setDataToShow(dataToHandle);
    }

    function _normalize(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
  }

  function getData() {
    setError(false);
    setLoading(true);
    if (!aggregate) {
      crudFactory
        .get({ collection, query, sort: { [sortBy]: -1 } })
        .then((datatableData) => {
          if (!isMounted.current) return;
          setData(datatableData);
          setLoading(false);
          onDataLoaded && onDataLoaded(datatableData);
        })
        .catch(() => setError(true));
    } else {
      crudFactory
        .runAggregates({
          bundle: [
            {
              collection,
              stages: aggregate,
            },
          ],
        })
        .then(([datatableData]) => {
          if (!isMounted.current) return;
          setData(datatableData);
          setLoading(false);
        })
        .catch(() => setError(true));
    }
  }

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.field === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleClick(item, rowIndex) {
    onRowClick && onRowClick(item, rowIndex);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  return (
    <div className="w-full flex flex-col border-solid border-1 border-gray-100">
      {ToolbarComponent && <ToolbarComponent />}
      <Box className="flex flex-row justify-end">
        {search && (
          <>
            <Search onChange={setSearchText} reload={reload || loading} />
            <Button variant="outlined" className="m-10 min-h-36 normal-case" size="small" onClick={() => getData()}>
              <AppIcon fa="fas fa-sync-alt" faSize="12px" className="mr-4" /> Refrescar
            </Button>
          </>
        )}
      </Box>
      <FuseScrollbars className="flex-grow overflow-x-auto">
        <Box>
          <Table stickyHeader aria-labelledby="tableTitle">
            <DataTableHead order={order} onRequestSort={handleRequestSort} rowCount={data.length} columns={columns} />

            {!error && (
              <TableBody>
                {!loading &&
                  dataToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => {
                    return (
                      <TableRow
                        className={'h-48' + (onRowClick ? ' cursor-pointer' : '')}
                        hover
                        aria-checked={false}
                        tabIndex={1}
                        key={n._id || i}
                        selected={false}
                        onClick={() => handleClick(n, i)}
                      >
                        {fields.map((f, i) => {
                          return (
                            <TableCell
                              key={i}
                              padding="default"
                              size="small"
                              component="td"
                              scope="row"
                              align={n.align}
                              style={{ fontSize: '1.35rem' }}
                            >
                              {columns[i].render && columns[i].render(n)}
                              {!columns[i].render && n[f]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
          {error && (
            <Box className="flex flex-row justify-center p-20 pt-128">
              <Typography variant="h5" align="center">
                Tenemos problemas para obtener los datos. Intenta recargar la página.
              </Typography>
            </Box>
          )}

          {!error && dataToShow.length === 0 && !loading && (
            <Box className="flex flex-row justify-center p-20 pt-128">
              <Typography variant="h5" align="center">
                No hay datos para mostrar
              </Typography>
            </Box>
          )}

          {loading && !error && (
            <Box height="400px" width="400px" m="auto" display="flex">
              <FuseLoading />
            </Box>
          )}
        </Box>
      </FuseScrollbars>

      {!error && (
        <TablePagination
          className="flex-shrink-0 border-t-1"
          component="div"
          count={dataToShow.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Filas por página"
          backIconButtonProps={{
            'aria-label': 'Anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Siguiente',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 50, 100]}
        />
      )}
    </div>
  );
}

export default DataTable;
